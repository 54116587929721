import axios from '@/axios';

const RESOURCE_NAME = '/upcoming-features';

export default {
    getFeatures() {
        return axios.get(`${RESOURCE_NAME}/get-features`);
    },

    getFeaturesCount() {
        return axios.get(`${RESOURCE_NAME}/get-features-count`);
    },
}
